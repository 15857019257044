#root {
    background: #181818;
    color: #fff;
    font-family: "Josefin Sans", sans-serif !important;
    /* height: 100vh;
    width: 100vw; */
}

/* .html {
    width: 85%;
}

.css {
    width: 90%;
}

.sass {
    width: 75%;
}

.tw {
    width: 80%;
}

.js {
    width: 55%;
}

.rj {
    width: 80%;
}

.nj {
    width: 30%;
}

.ej {
    width: 30%;
}

.md {
    width: 55%;
}

.seo {
    width: 65%;
} */


/* .html-abbr {
    left: 85%;
}

.css-abbr {
    left: 90%;
}

.sass-abbr {
    left: 75%;
}

.tw-abbr {
    left: 80%;
}

.js-abbr {
    left: 55%;
}

.rj-abbr {
    left: 80%;
}

.nj-abbr {
    left: 30%;
}

.ej-abbr {
    left: 30%;
}

.md-abbr {
    left: 55%;
}

.seo-abbr {
    left: 65%;
} */